import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
// Cargar el plugin
dayjs.extend(isBetween);

export function isInChristmasRange(date: Date) {
    const inputDate = dayjs(date);
    const year = inputDate.year();

    // Definir el rango de fechas
    const startOfRange = dayjs(`${year}-12-20`); // 20 de diciembre del año actual
    const endOfRange = dayjs(`${year + 1}-01-02`); // 2 de enero del año siguiente

    // Si estamos entre diciembre y enero, ajustar el año del rango
    if (inputDate.month() === 11) {
        // Si estamos en diciembre
        return inputDate.isBetween(startOfRange, endOfRange, null, '[]');
    }
    if (inputDate.month() === 0) {
        // Si estamos en enero
        const startOfRangePreviousYear = dayjs(`${year - 1}-12-20`);
        const endOfRangeThisYear = dayjs(`${year}-01-02`);
        return inputDate.isBetween(
            startOfRangePreviousYear,
            endOfRangeThisYear,
            null,
            '[]',
        );
    }

    // Fuera de los meses de diciembre y enero
    return false;
}
