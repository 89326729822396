/* This example requires Tailwind CSS v2.0+ */
import { Dialog } from "@headlessui/react";
import NotificationScreen from "../../../views/MainScreens/NotificationsScreen";
import { CloseIcon } from "../../../icons";

type NotificationSlideOverProps = {
    open: boolean;
    setOpen: (value: boolean) => void;
    type: "" | "conversation" | "appointment";
};

export default function NotificationSlideOver({ open, setOpen, type }: NotificationSlideOverProps) {
    const getTitle = () => {
        switch (type) {
            case "conversation":
                return "Message notifications";
            case "appointment":
                return "Appointment notifications";
            case "":
            default:
                return "Notifications";
        }
    };
    return (
        <Dialog as="div" className="fixed inset-0 overflow-hidden z-10" open={open} onClose={setOpen}>
            <div className="absolute inset-0 overflow-hidden">
                <Dialog.Overlay className="absolute inset-0 bg-gray-500/75 transition-opacity" />
                <div className="fixed inset-y-0 right-0 md:pl-10 max-w-full flex">
                    <div className="w-screen md:max-w-xl">
                        <div
                            id="scrollTarget"
                            className="flex flex-col pt-20 py-6  bg-reyna-grey-1 shadow-xl overflow-y-scroll h-full"
                        >
                            <div className="px-4 sm:px-6">
                                <div className="flex items-start justify-between">
                                    <Dialog.Title className="text-lg font-medium text-gray-900">
                                        {getTitle()}
                                    </Dialog.Title>
                                    <div className="ml-3 h-7 flex items-center">
                                        <button
                                            type="button"
                                            className="rounded-md text-gray-400 hover:text-gray-500 outline-none focus-visible:!shadow-none focus-visible:!ring-0"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">Close panel</span>
                                            <CloseIcon className="h-6 w-6 fill-current" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6 relative flex-1 px-4 sm:px-6">
                                {/* Replace with your content */}
                                <NotificationScreen setOpen={setOpen} type={type} />
                                {/* /End replace */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
