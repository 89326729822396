export const DB_ENUMS = {
    CURRENT_CREDIT_SCORE: ["N/A", "< 620", "621-640", "641-660", "661-680", "681-700", "701-720", "721-740", "> 740"],
    CURRENT_MONTHLY_INCOME: ["No Income", "$1k-$5k", "$5k-$10k", "$10k-$50k", ">50k"],
    CREDIT_CARDS: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    MESSAGE_TYPE: ["sms", "email"],
    MESSAGE_TYPE_BOTH: ["sms", "email", "both"],
    CONTACT_TYPE: ["user", "credit_lead", "credit_client", "restate_lead", "restate_client"],
    CRUD_TYPE: ["create", "read", "update", "delete"],
    ACTIVITY_ACTION: ["create", "edit", "delete"],
    ACTIVITY_TYPE: ["message", "group", "task", "appointment", "campaign", "lead", "client", "bulk-message"],
    ACTIVITY_TABS: [
        "BANKRUPTCY",
        "OVERALL",
        "DOCUMENTS",
        "NOTES",
        "PAYMENT",
        "FINANCIAL DISCLOSURE",
        "PROCESSING",
        "SETTLEMENT",
        "ACCOUNTS",
        "APPOINTMENTS",
        "TASKS",
        "PROGRAMS",
        "FUNDING",
    ],
    STATUS_PENDING: ["Contact", "Agent"],
    SOURCE_TYPE: [
        "facebook",
        "twitter",
        "realestatelane_website",
        "creditclubrepair_website",
        "MANUAL",
        "phone",
        "email",
        "instagram",
        "tiktok",
    ],
    STATUS_TYPE: [
        "Business Associate",
        "Cold Lead",
        "Competitor",
        "Consultant",
        "Contractor",
        "Co-Worker",
        "Customer",
        "Decision Maker",
        "Distributor",
        "Employee",
        "Family",
        "Friend",
        "Gate Keeper",
        "Hot Lead",
        "Influencer",
        "Investor",
        "Key Customer",
        "Lead",
        "Manufacturer",
        "Personal",
        "Prospect",
        "Qualified Lead",
        "Reseller",
        "Retailer",
        "Shareholder",
        "Supplier",
        "Vendor",
        "Warm Lead",
        "Wholesaler",
    ],
    GOAL_TYPE: [""],
    PROGRAM_TYPE: [""],
    LEGAL_STATUS: ["married", "single"],
    CLASS_CERT: ["1st", "2nd"],
    OWN_RENT: ["own", "rent"],
    PAYMENT_TYPE: ["mortgage", "rent"],
    INSURANCE_TYPE: ["home", "rent"],
    CARRIER_TYPE: ["own", "rent"],
    EMPLOYEE_TYPE: ["w2", "1099"],
    ACCOUNT_STATUS: [""],
    SETTLEMENT_STATUS: [""],
    PRIORITY_TYPE: ["Highest", "High", "Medium", "Low"],
    APPOINTMENT_TYPE: ["lead_appointment", "client_appointment"],
    GROUP_STATUS: ["active", "no_active"],
    ROLE_PRETIFIER: [
        { value: "super_admin", name: "Super Admin" },
        { value: "agent_all", name: "Agent" },
        { value: "agent_credit", name: "Credit Agent" },
        { value: "agent_restate", name: "Real Estate Agent" },
        { value: "admin_all", name: "Admin" },
        { value: "admin_credit", name: "Credit Admin" },
        { value: "admin_restate", name: "Real Estate Admin" },
    ],
    ROLE_USER: [
        // { value: "super_admin", label: "Super Admin" },
        { value: "Agent", label: "Agent" },
        { value: "Credit Agent", label: "Credit Agent" },
        { value: "Real Estate Agent", label: "Real Estate Agent" },
        { value: "Admin", label: "Admin" },
        { value: "Credit Admin", label: "Credit Admin" },
        { value: "Real Estate Admin", label: "Real Estate Admin" },
        { value: "Subadmin", label: "Subadmin" },
        { value: "Owner", label: "Owner" },
    ],
    MESSAGE_TYPE_DROPDOWN: [
        { value: "sms", label: "sms" },
        { value: "email", label: "email" },
    ],
    CONTACT_TYPE_DROPDOWN: [
        { value: "lead", label: "Lead" },
        { value: "client", label: "Client" },
        { value: "both", label: "Both" },
    ],
    CONTACT_TYPE_CAMPAIGN: [
        "lead",
        "client",
        "both"
    ],
    MESSAGE_TYPE_BOTH_DROPDOWN: [
        { value: "sms", label: "Text Message" },
        { value: "email", label: "Email" },
        { value: "both", label: "Both" },
    ],
    RED_FLAG: ["true", "false"],
    CONTACTED: ["true", "false"],
    CALENDAR_FILTER: ["All", "Tasks", "Appointments"],
    PAYMENT_OPTIONS: [
        { value: 0, label: 'Paid in full' },
        { value: 1, label: 1 },
        { value: 2, label: 2 },
        { value: 3, label: 3 },
        { value: 6, label: 6 },
        { value: 8, label: 8 },
        { value: 9, label: 9 },
        { value: 12, label: 12 },
        { value: 18, label: 18 },
        { value: 24, label: 24 },
    ],
    YES_NO: [
        { value: "true", label: 'Yes' },
        { value: "false", label: 'No' },
    ],
};
